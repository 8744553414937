require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/85c535a85611c50af15d9c7256ac8034/code.js', () => { 

BackendlessUI.Functions.Custom['fn_85c535a85611c50af15d9c7256ac8034'] = function fn_85c535a85611c50af15d9c7256ac8034() {
return 
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/landing/components/e32ec74379aa74d806d479e79c7cf613/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      console.log('aabbcc');

  },
  /* handler:onMouseOver */
  /* content */
}))

